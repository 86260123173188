import React from 'react'
import { useRoutes } from 'react-router-dom'
import Home from './Home'
import Admin from './Admin'
import PrivateRoute from './PrivateRoute'
import LoginForm from './LoginForm'
// import Test from './Test'

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <Home />,
        },
        {
            path: '/admin',
            element: <PrivateRoute><Admin /></PrivateRoute>,
        },
        {
            path: '/login',
            element: <LoginForm />,
        },
        // {
        //     path: '/test',
        //     element: <Test />,
        // },
    ])
}
