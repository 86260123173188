import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './Router'
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './AuthContext';

export default function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  )
}
