import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

const ExcelExport = ({ data, fileName, disabled }) => {
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    return (
        <Button
            variant="outlined"
            fullWidth
            color="secondary"
            sx={{ height: "100%" }}
            onClick={exportToExcel}
            disabled={disabled}
        >
            Download
        </Button>
    );
}

export default ExcelExport;