import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('Authen') !== null || false);
    const authendata = localStorage.getItem('Authen') !== null ? JSON.parse(localStorage.getItem('Authen')) : ""
    const login = () => {
        setIsAuthenticated(true)
    };
    const logout = () => {
        setIsAuthenticated(false)
        localStorage.clear()
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, authendata }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
