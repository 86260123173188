import { Box, Button, Container, Grid, TextField, Typography, Stack } from '@mui/material'

import React from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import qs from 'qs';
import axios from 'axios';

import logo from "../src/logo_treasure_latex_factory.jpg"
import { useSnackbar } from 'notistack';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export default function LoginForm() {
    const { login } = useAuth();
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const formSchema = yup.object().shape({
        userid: yup.string().required('required'),
        password: yup.string().required('required'),
    });
    const formik = useFormik({
        initialValues: {
            userid: "",
            password: "",
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            const databody = {
                ...values
            }
            const datatoken = qs.stringify({
                'username': 'supoj@hotmail.com',
                'password': 'Sit@2020',
                'grant_type': 'password'
            });
            axios.post('https://api5shop.sitconsultant.com/token', datatoken)
                .then((restoken) => {
                    axios.post('https://api5shop.sitconsultant.com/v0/app/login', databody, {
                        headers: { 'Content-Type': 'application/json', 'Authorization': `bearer ${restoken.data.access_token}`, 'Appid': "sitpos_5shop" }
                    })
                        .then((res) => {
                            handleFormReset()
                            localStorage.setItem("Authen", JSON.stringify(res.data))
                            login()
                            navigate("/admin")
                        })
                        .catch((error) => {
                            handleSnackbar({ message: "Error,please try again later", variant: "error" })
                        })
                })
                .catch((error) => {
                    handleSnackbar({ message: "Error,please try again later", variant: "error" })
                })
        },
    });

    const handleFormReset = () => {
        formik.resetForm()
    }

    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };

    return (
        <Container maxWidth="sm" sx={{ backgroundColor: "white" }}>
            <Box>
                <img src={logo} width="100%" style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} alt='logo' />
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            color: "#906C4E"
                        }}
                    >
                        <Grid item xs={12} sx={{
                            textAlign: "center",
                            margin: "10px"
                        }} >
                            <Typography variant="h5" >ลงทะเบียนเข้าร้านยางพารา Treasure Latex Factory</Typography>
                            <Typography variant="h6" >注册進乳胶店的政策 Treasure Latex Factory</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            Username
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.userid && Boolean(formik.errors.userid)}
                                helperText={formik.touched.userid && formik.errors.userid}
                                name="userid"
                                type="text"
                                value={formik.values.userid}
                                id="outlined-basic-Username"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            Password
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                name="password"
                                value={formik.values.password}
                                type="password"
                                id="outlined-basic-Password"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ marginY: "20px" }}>
                        <Stack spacing={1} justifyContent="space-between" direction="row" >
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                >
                                    Login
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Form>
            </FormikProvider >
        </Container>
    )
}
